export const LIST_POSITION = [
    {
        id: 1,
        name: 'Stylist',
    },
    {
        id: 2,
        name: 'Skinner',
    },
    {
        id: 4,
        name: 'Bảo vệ',
    },
    {
        id: 5,
        name: 'Chuyên viên tư vấn',
    },
    {
        id: 6,
        name: 'Checkout',
    },
    {
        id: 9,
        name: 'Trưởng salon (SM)',
    },
    {
        id: 40,
        name: 'Stylist Master',
    },
    {
        id: 39,
        name: 'Quản lý chuyên môn',
    },
];