import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
// dayjs().tz('Europe/London')

import { ColumnConfig, SearchConfig, TypeSearch } from '../../base/util'
import FormComponent from '../../base/Form'
import { Checkbox, Button, notification, Modal } from 'antd'
import { PlayCircleOutlined, ApiOutlined, LoadingOutlined } from '@ant-design/icons'
import { getListRecord, getRecordIntent, createAudio2Text, createAudioScript } from '../api'
import { checkValueAI } from '../../../utils/helpers/AIResultCheckPoint'
import { convertISOToCustomFormat } from '../../../utils/helpers'
import AIRecordDetail from './Detail'
import { getSalons } from 'api/catalog'
import { LIST_POSITION } from 'utils/const/ListPosition'

export const ListAIRecord = () => {
  const navigate = useNavigate()
  const [reload, setReload] = useState(false)
  const [listSalon, setListSalon] = useState([])
  const [recordIntent, setRecordIntent] = useState([])
  const [loadingBtn, setLoadingBtn] = useState()

  const handleFetchListSalon = async () => {
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleFetchRecordItent = async () => {
    try {
      const response: any = await getRecordIntent()
      if (response) {
        setRecordIntent(response)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  useEffect(() => {
    handleFetchListSalon()
    handleFetchRecordItent()
  }, [])
  const [column, setColumn] = useState<any>([])
  const handleCreateText = async (record: any) => {
    console.log(record.Id, 'id')
    try {
      setLoadingBtn(record.Id)
      const response: any = await createAudio2Text(record.Id)
      if (response) {
        notification.success({
          description: 'Thành công',
          message: 'Bạn đã thực hiện chuyển đổi Audio sang văn bản thành công'
        })
        setReload(true)
        setLoadingBtn(undefined)
      }
    } catch (error) {
      setLoadingBtn(undefined)
      console.error('Error:', error.message)
    }
  }
  const handleCreateScript = async (record: any) => {
    console.log(record.Id, 'id')
    try {
      setLoadingBtn(record.Id)
      const response: any = await createAudioScript(record.Id)
      if (response) {
        notification.success({
          description: 'Thành công',
          message: 'Bạn đã thực hiện phân loại kịch bản Audio thành công'
        })
        setReload(true)
        setLoadingBtn(undefined)
      }
    } catch (error) {
      setLoadingBtn(undefined)
      console.error('Error:', error.message)
    }
  }

  const formatTime = (seconds) => [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(':')
  useEffect(() => {
    if (recordIntent.length > 0) {
      const dynamicColumnsConfig = recordIntent.map((data, index) => ({
        title: data.ShortName,
        key: data.Slug,
        render: (record) => {
          switch (checkValueAI(record.AIScriptResultOptimized, data.Slug)) {
            case 'success':
              return <img src='/icon/success.png' width={20} height={20} alt='img'></img>
            case 'fail':
              return <img src='/icon/fail.png' width={20} height={20} alt='img'></img>
            case 'none':
              return <></>
          }
        },
        width: 20
      }))

      // Sử dụng spread operator để ghép mảng và set vào state
      setColumn([
        {
          title: 'Salon',
          dataIndex: 'SalonName',
          key: 'SalonName'
        },
        {
          title: 'Tên App',
          dataIndex: 'AppName',
          key: 'AppName'
        },
        {
          title: 'ID nhân viên',
          dataIndex: 'StaffId',
          key: 'StaffId'
        },
        {
          title: 'Tên nhân viên',
          dataIndex: 'StaffName',
          key: 'StaffName'
        },
        {
          title: 'ID Booking',
          dataIndex: 'BookingId',
          key: 'BookingId'
        },
        {
          title: 'ID Bill',
          dataIndex: 'BillId',
          key: 'BillId'
        },
        {
          title: 'Input Audio',
          key: 'InputAudio',
          render: (record) => (
            <div>
              {record.InputAudio ? (
                <>
                  {JSON.parse(record.InputAudio) ? (
                    <>
                      {JSON.parse(record.InputAudio).map((item, i) => (
                        <>
                          {item.type === 'interview' ? (
                            <div key={i} className='max-w-[180px]'>
                              {item.fullName && (
                                <p>
                                  <span className='font-medium'>Tên:</span> <span>{item?.fullName || '-'}</span>
                                </p>
                              )}
                              {item.startTime && (
                                <p>
                                  <span className='font-medium'>Bắt đầu:</span>{' '}
                                  <span>{dayjs.unix(item.startTime).format('DD-MM-YYYY HH:mm')}</span>
                                </p>
                              )}
                              {item.position && (
                                <p>
                                  <span className='font-medium'> Vị trí:</span>{' '}
                                  <span>{LIST_POSITION.find((pos) => pos.id === item?.position)?.name || '-'}</span>
                                </p>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          )
        },
        {
          title: 'AI văn bản',
          key: 'AItext',
          render: (record) => (
            <>
              {record.MemobotAudioId ? (
                <img src='/icon/success.png' width={20} height={20} alt='img'></img>
              ) : (
                <>
                  {loadingBtn && loadingBtn === record.Id ? (
                    <Button disabled>
                      <LoadingOutlined />
                    </Button>
                  ) : (
                    <Button onClick={() => handleCreateText(record)}>Tạo</Button>
                  )}
                </>
              )}
            </>
          ),
          width: 20
        },
        {
          title: 'AI kịch bản',
          key: 'AI script',
          render: (record) => (
            <>
              {record.AIScriptResultOptimized ? (
                <img src='/icon/success.png' width={20} height={20} alt='img'></img>
              ) : (
                <Button onClick={() => handleCreateScript(record)}>Tạo</Button>
              )}
            </>
          ),
          width: 20
        },
        {
          title: 'Tạo lúc',
          key: 'CreatedTime',
          render: (record) => <p>{dayjs.utc(record.CreatedTime).format('DD/MM/YYYY HH:mm')}</p>
        },
        ...dynamicColumnsConfig,
        {
          title: 'Công cụ',
          key: 'Publish',
          render: (record) => (
            <>
              <Button onClick={() => handleShowRecord(record.Id)} className='mt-2'>
                <PlayCircleOutlined style={{ fontSize: 20, position: 'relative', top: '-3px' }} />
              </Button>
            </>
          )
        }
      ])
    }
  }, [recordIntent])

  const handleShowRecord = async (id: number) => {
    setAiRecordId(id)
    setIsModalOpen(true)
  }
  const columnSearch: SearchConfig[] = [
    {
      label: 'Thời gian',
      param: 'date',
      type: TypeSearch.date,
      buttonDate: true
    },
    {
      label: 'Chọn Salon',
      type: TypeSearch.select,
      param: 'salonId',
      placeholder: 'Chọn Salon',
      options: listSalon,
      fieldNames: { label: 'name', value: 'id' }
      // require: true
    },
    {
      label: 'Tên App',
      type: TypeSearch.select,
      param: 'appName',
      placeholder: 'Nhập vào Tên App',
      options: [
        { label: '30Shine Stylist', value: 'STVN' },
        { label: '30Shine Skinner', value: 'SKVN' },
        { label: '30Shine checkin', value: 'FriendlyCheckin' },
        { label: '30Shine SMVN', value: 'SMVN' },
        { label: 'MOYO checkin', value: 'MOYO CHECKIN' }
      ]
      // require: true
    },
    {
      label: 'ID nhân viên',
      type: TypeSearch.input,
      param: 'staff',
      placeholder: 'Nhập vào ID nhân viên'
      // require: true
    },
    {
      label: 'ID Booking',
      type: TypeSearch.input,
      param: 'bookingId',
      placeholder: 'Nhập vào ID Booking'
      // require: true
    },
    {
      label: 'ID Bill',
      type: TypeSearch.input,
      param: 'billId',
      placeholder: 'Nhập vào ID Bill'
      // require: true
    }
  ]

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [aiRecordId, setAiRecordId] = useState(null)
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/staff/ai-record'
        title='Danh sách bản ghi âm AI'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListRecord}
        handleChangeValue={null}
        exportCsv={false}
        linkADD=''
        reload={reload}
        setReload={setReload}
        exportData={null}
      ></FormComponent>

      <Modal
        title='Nghe bản ghi âm'
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
        width={1200}
      >
        {aiRecordId && <AIRecordDetail aiRecordId={aiRecordId} recordIntent={recordIntent} />}
      </Modal>
    </div>
  )
}
